<template>
  <div id="app" >
    
  
    <div class="logo-container">
      <a href="https://memorialparquemaceio.com.br">
      <img src="./assets/logo.svg" alt="Logo" style="width:250px"/>
      </a>
    </div>
    <br/>
    <div class="search-container">
      <input
        class="search-input"
        type="text"
        placeholder="Nome do Sepultado"
        v-model="search_name"
      />
      <div class="search-icon" @click="search">
        <!-- You can use an SVG icon here or an image with a search icon -->
        <!-- For example, using an SVG icon -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="white"
          width="32px"
          height="32x"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            d="M15.5 14h-.79l-.28-.27a6.5 6.5 0 1 0-.7.7l.27.28v.79l4 3.85 1.5-1.5-3.85-4zm-5 0a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9z"
          />
        </svg>
      </div>
    </div>

    <div v-if="loading" class="loading-indicator">

      <img src="./assets/loading.gif" alt="Carregando..." />


    </div>

    <div class="card-container" style="margin-bottom: 60px;">
      <div v-if="cardRows && cardRows.length">
        <CardItem
          v-for="card in cardRows"
          :key="card.id"
          :nome="card.falecido"
          :falecimento="card.falecimento"
          :sepultamento="card.sepultamento"
          :quadra="card.quadra"
          :letra="card.letra"
          :jazigo="card.jazigo"
          :cardColor="cardColor"
        />
      </div>
      <div v-else>
       <span v-if="loading == false"> Não encontrado óbitos </span>
      </div>
      

    </div>

    
         <!-- Controles de paginação -->
         <div v-if="totalPages > 1" class="pagination">
          <button
            class="pagination-button"
            @click="goToPage(currentPage - 1)"
            :disabled="currentPage === 1"
          >
            <i class="fas fa-chevron-left"></i> <!-- Ícone de seta para a esquerda -->
          </button>
          <span class="pagination-text"> Página {{ currentPage }} de {{ totalPages }}</span>
          <button
            class="pagination-button"
            @click="goToPage(currentPage + 1)"
            :disabled="currentPage === totalPages"
          >
            <i class="fas fa-chevron-right"></i> <!-- Ícone de seta para a direita -->
          </button>
        </div>
        <br/>
    <div style="height: 100px;"></div>

    <footer class="footer">
      <div class="footer-content">
        <img src="./assets/LOGOSVG4.svg" alt="Grupo Parque das Flores" style="width: 84px; height: 64px;">
        <p style="color: white; text-align: center;">©2023 Empresa do Grupo Parque -
          Todos os direitos reservados <br/>
        </p>
      </div>
    </footer>


  </div>
</template>

<script>
import CardItem from './components/CardItem.vue'
import Contrato from './services/contrato'

export default {
  name: 'App',
  components: {
    CardItem
  },

  data() {
    return {
      cardColor: "darkgreen",
      loading: false,
      currentPage: 1,
      totalPages:1,
      pageSize: 20,
      cardRows: [
  
      ],
      search_name: '',
    };
  },

  mounted() {
   
  },

  methods:{

    goToPage(pageNumber) {
      if (pageNumber >= 1 && pageNumber <= this.totalPages) {
        this.currentPage = pageNumber;
        this.search();
      }
    },
    
   


    search(){



      
      if(this.search_name.length < 5){
        alert('Por Favor, o campo de buscar requer no minimo 5 caracteres');
        return 
      }

      this.cardRows = [];
      this.loading = true; 

      Contrato.lista(this.search_name, "MEMORIAL PARQUE MACEIO", this.currentPage, this.pageSize)
              .then(response => {
                        if (response.status === 200) {
                         
                          this.cardRows = response.data.content; // Extrair os dados da resposta paginada
                          this.totalPages = response.data.totalPages; // Total de páginas disponíveis
                        }
                      })
                      .catch(error => {
                        // Lida com erros
                        console.error('Erro a', error);
                      }) .finally(() => {
                        // Após o término do download, oculte o diálogo de progresso
                        this.loading = false;
                      });
            },



  } 
}

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding-top: 40px;
}

.logo-container {
  text-align: center;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card-item-container {
  min-height: 400px; /* Definir a altura mínima para os cards */
  margin: 10px; /* Adicionar um espaçamento entre os cards */
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size:14px
}
.footer {
  background-color: #254828;
  position: fixed; /* Definir o footer como posição fixa */
  bottom: 0; /* Ancorar o footer na parte inferior */
  left: 0;
  width: 100%;
}

.search-input:focus {
  /* Change the border-color to green when the input is focused */
  border-color: #4caf50 !important;
}

.search-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.search-input {
  padding: 15px; /* Increase padding to make the input larger */
  font-size: 20px; /* Increase font-size to make the text larger */
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 400px; /* Increase width to make the input wider */
  /* Add green border-bottom when the input is focused */
  border-bottom: 2px solid transparent;
  transition: border-color 0.1s;
}

.search-input:focus {
  /* Change the border-color to green when the input is focused */
  border-color: #4caf50;
}

.search-icon {
  margin-left: -40px; /* Adjust the value as needed for spacing */
  display: flex;
  align-items: center;
  background-color: #4caf50; /* Green background for the search icon */
  padding: 12px; /* Increase padding to make the icon container larger */
  border-radius: 0 5px 5px 0;
  cursor: pointer;
}

.loading-indicator {
  font-size: 18px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
}


/* Estilize a imagem de carregamento (opcional) */
.loading-indicator img {
  width: 200px;
  height: 200px;
}


/* Add some hover effects to the search icon to indicate interactivity */
.search-icon:hover {
  background-color: #45a049;
}


/* Estilize o texto de paginação */
.pagination-text {
  font-size: 16px;
  font-family: inherit;
  margin: 0 10px; /* Adicione espaçamento horizontal de 10 pixels */
}


</style>




